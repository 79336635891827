import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatFilter } from 'src/app/shared/util/api.util';
import { RESTService } from '../../../shared/services/rest.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RiskService {
  constructor(private rest: RESTService) { }

  get(skip, limit, filter?): Observable<any> {

    const filterHasValues = filter
    ? Object.values(filter).some((v) => {
      if (Array.isArray(v)) {
        return v.length > 0 ? true : false;
      } else {
        return !!v;
      }
    })
    : undefined;

    if (filterHasValues) {
      const filtersArray = [];
      const statusFilter = formatFilter(filter.statuses, 'status', 'in');

      if (statusFilter) filtersArray.push(statusFilter);
      
      return this.rest.get(
        `/evaluations?skip=${skip}&limit=${limit}&filter=${filtersArray.join(',')}`
      );
    }else {
      return this.rest.get(`/evaluations?skip=${skip}&limit=${limit}&sort=usedCreditLine&order=desc`);
    }
 
  }

  byId(id) {
    return this.rest.get(`/evaluations/${id}`);
  }
  pospone(entity){
    const entityId = entity._id;
    return this.rest.patch(`/evaluations/${entityId}`, entity);
  }

  getAll(): Observable<any> {
    return this.rest.get(`/evaluations`);
  }

  save(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.create(entity);
      case 'edit':
        return this.update(entity);
    }
  }

  requestEvaluation(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.request(entity);
      
    }
  }

  request(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.post(`/debtors`, entity);
  }

  saveNote(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.createNote(entity);
      case 'edit':
        return this.updateNote(entity);
      case 'delete':
          return this.deleteNote(entity);
    }
  }

  create(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.post(`/evaluations`, entity);
  }

  update(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.patch(`/evaluations/${entityId}`, entity);
  }

  createNote(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.post(`/debtors`, entity);
  }

  updateNote(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.patch(`/debtors/${entityId}`, entity);
  }

  deleteNote(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.delete(`/debtors/${entityId}`, entity);
  }

  sort(skip, limit, entity) : Observable<any> {
    return this.rest.get(`/evaluations?skip=${skip}&limit=${limit}&sort=${entity.property}&order=${entity.order}`);
  }

  calculateRating(finalValue) {
    let rating = '';
  
    if (!isNaN(finalValue)) {
      if (finalValue < 70) {
        rating = '---'
      } else if (finalValue <= 80 && finalValue >= 70) {
        rating = 'B'
      } else if (finalValue <= 85 && finalValue > 80) {
        rating = 'B+'
      } else if (finalValue <= 90 && finalValue > 85) {
        rating = 'A'
      } else if (finalValue <= 95 && finalValue > 90) {
        rating = 'A+'
      } else {
        rating = 'A++'
      }
    } else {
      rating = 'error';
    }
  
    return rating;
  }

  saveFiles(entityId, files): Observable<any> {
      return this.rest.post(`/evaluations/${entityId}/documents`, files);
  }


  downloadDocumentsFiles(evaluationId): Observable<any> {
    return this.rest.get(`/evaluations/${evaluationId}/documents`);
  }

  downloadOneDocument(evaluationId, doc): Observable<any> {
    return this.rest.patch(`/evaluations/download/document/${evaluationId}`, doc);
  }

  deleteOneDocument(doc, mode ): Observable<any> {
    if(mode === 'edit'){
      let evaluationId = doc._id
      return this.rest.patch(`/evaluations/documents/${evaluationId}`, doc);
    }
  }

  getMimeTypes(file) {
    if(file){
      let extension = file?.split('.')[1];
      return extension
    }
  }

  getTotalValue(evaluation){
    return this.rest.post(`/evaluations/${evaluation._id}/calculate`, evaluation);
  }

}
