import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-donwload-popup',
  templateUrl: './confirmation-donwload-popup.component.html',
  styleUrls: ['./confirmation-donwload-popup.component.scss'],
})
export class ConfirmationDonwloadPopupComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDonwloadPopupComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      notShowAgain: [''],
    });
  }

  confirm() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.dialogRef.close({ data: this.form.value });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
