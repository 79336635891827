<div *ngIf="!entity.isExternal && !(entity.type == 'DESEMBOLSO' && entity.isConfirming) && (role === 'admin' || role === 'super admin')"
    class="one-line container">
    <div class="container-center">
        <h3 class="mb-30">OBSERVACIONES</h3>
    </div>
    <div (click)="addObservation()" class="container-link icon-text-align right">
        <a class="cp">Nueva observación</a>
        <mat-icon>add</mat-icon>
    </div>
</div>

<div *ngIf="!entity.isExternal && !(entity.type == 'DESEMBOLSO' && entity.isConfirming) && (role === 'admin' || role === 'super admin')"
    class="bank-and-currency row">
    <ng-container *ngIf="obs?.length > 0; else defaultObs">
        <table class="invoice-table">
            <thead>
                <tr>
                    <th class="col-invoice">Descripcion</th>
                    <th class="col-amount">Monto</th>
                    <th class="col-retention">Comentario</th>
                    <th class="col-net-amount">¿IGV?</th>
                    <th class="col-net-amount">¿Incluir?</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="obs | sort:('createdAt'):'closest' as obsSorted">
                    <tr *ngFor="let ob of obsSorted" class="table-row" [ngClass]="{ 'active' : ob.selected }">
                        <td><ng-container *ngIf="ob.description ? ob.description?.length > 30: false">
                                <p [ngClass]="{'font-tached' : ob.isDone === true}" class="tooltip-container-not-dots">
                                    <span class="noWrap">
                                        {{(ob?.description | limitTo: '20') + (ob?.description.length > 20 ? '...' :
                                        '')}}
                                    </span>
                                    <span class="tooltiptext-left">{{ob?.description}}</span>
                                </p>
                            </ng-container>
                            <ng-container *ngIf="ob.description?.length <= 30">
                                <span [ngClass]="{'font-tached' : ob.isDone === true}">
                                    {{ob.description ? ob.description.trim() !== '' ? ob.description : '---' : '---'}}
                                </span>
                            </ng-container>
                        </td>
                        <td>{{ ob.currency | uppercase }} {{ ob.amount | numberSpacing}}</td>
                        <td>
                            <ng-container *ngIf="ob.comment?.length > 30">
                                <p class="tooltip-container-not-dots">
                                    <span class="noWrap">
                                        {{(ob?.comment | limitTo: '20') + (ob?.comment.length > 20 ? '...' : '')}}
                                    </span>
                                    <span class="tooltiptext-left">{{ob?.comment}}</span>
                                </p>
                            </ng-container>
                            <ng-container *ngIf="ob.comment?.length <= 30">
                                <span>
                                    {{ob.comment ? ob.comment.trim() !== '' ? ob.comment : '---' : '---'}}
                                </span>
                            </ng-container>
                        </td>
                        <td>
                            <app-checkbox [disabled]="ob.selected" (selectionChanged)="setIGV(ob)"
                                [checked]="ob.isTaxable" content="" color="accent">
                            </app-checkbox>
                            <!-- <app-slide-toggle [firstLabel]="'si'" [secondLabel]="'no'" [checked]="!ob.isTaxable"
                            (selectionChanged)="setIGV(ob)"></app-slide-toggle> -->
                        </td>
                        <td>
                            <app-slide-toggle [checked]="ob.selected" [firstLabel]="'si'" [secondLabel]="'no'"
                                (selectionChanged)="setInclude(ob)"></app-slide-toggle>
                        </td>

                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
    <ng-template #defaultObs>
        <div class="container-disclaimer">
            <p class="disclaimer">No hay observaciones.</p>
        </div>
    </ng-template>
</div>
<div class="row-text">
    <div class="bank-and-currency row">
        <div class="bank-container">
            <p class="tiny bank-label">Asunto</p>
            <p class="value">{{entity.subject}}</p>
        </div>
    </div>
    <div class="row row-aligment">
        <div class="bank-container row-cc">
            <p class="tiny bank-label">Para</p>
            <p class="value break-line">{{entity.to | stringNextLine: ','}}</p>
        </div>
        <div class="bank-container right row-cc">
            <p class="tiny bank-label">CC</p>
            <p class="value break-line">{{entity.cc | stringNextLine: ','}}</p>
        </div>
    </div>
    <div class="row" *ngIf="!entity.isExternal">
        <div class="bank-container">
            <p class="tiny bank-label">Notas</p>
            <p class="value mw-200" *ngIf="entity.notes">{{ entity.notes }}</p>
            <p class="value" *ngIf="!entity.notes">--</p>
        </div>
        <div class="bank-container right">
            <p class="tiny bank-label">Archivo adjunto</p>
            <p class="value" *ngIf="!entity.file">--</p>
            <p class="value" *ngIf="entity.file">voucher.jpg</p>
        </div>
    </div>
</div>
<!-- <div [innerHTML]="html | safeHtml" class="preview"></div> -->
<app-email-preview-html [html]="html"></app-email-preview-html>