import { SocketService } from 'src/app/shared/services/socket.service';
import { ChecklistModalComponent } from './../../../../../shared/UI/organisms/checklist-modal/checklist-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { NavigationExtras, Router } from '@angular/router';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import lodash from 'lodash';
import {
  filtersOptions,
  filtersOptionsAM,
  operatorsOptios,
} from '../../../../../shared/enums/filter.enum';
import { InvoiceForSaleDialogComponent } from 'src/app/features/invoices/UI/organisms/invoice-for-sale-dialog/invoice-for-sale-dialog.component';
import { ConfirmInvoiceForSaleComponent } from 'src/app/features/invoices/UI/organisms/confirm-invoice-for-sale/confirm-invoice-for-sale.component';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { NewConfirmationModalComponent } from 'src/app/shared/UI/molecules/new-confirmation-modal/new-confirmation-modal.component';
import { InvoiceStatus } from '../../../../../shared/enums/InvoiceStatus.enum';
import { Toaster } from 'ngx-toast-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { PayClientAdvanceDialogComponent } from 'src/app/features/invoices/UI/organisms/pay-client-advance-dialog/pay-client-advance-dialog.component';
import moment from 'moment';
import { EmailBoDialogComponent } from 'src/app/features/invoices/UI/organisms/email-bo-dialog/email-bo-dialog.component';
import { EmailPreviewComponent } from 'src/app/features/invoices/UI/organisms/email-preview/email-preview.component';
import { EmailType } from 'src/app/shared/enums/EmailType.enum';

@Component({
  selector: 'app-list-operations',
  templateUrl: './list-operations.component.html',
  styleUrls: ['./list-operations.component.scss'],
})
export class ListOperationsComponent implements OnInit {
  operations$;
  filteredOperations;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  profile;
  filtersOptions;
  operatorsOptios = operatorsOptios;
  allAccountManagers;
  allCompanies;
  filterItemsArray;
  operationsReady = [];
  filteredInput;
  filterOptions;

  inputOptions;

  inputLable;

  attribute = {
    label: 'Seleccionar el campo',
    options: undefined,
  };
  operator = {
    label: 'Selecciona...',
    options: operatorsOptios,
  };
  isAdvanceModalOpened;
  openModalSchedule;
  sendEmail;
  dataEmail;
  subscription;
  loggedInUserRole;
  selectedInvoice;
  scheduleInvoice;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private overlayService: OverlayService,
    public service: InvoiceService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
          this.setFilterOption();
        }
      });

    this.store.select(InvoiceSelectors.allOperations).subscribe((data) => {
      this.operations$ = data;

      this.getFilterArray();

      this.filteredOperations = this.operations$.filter(
        (operation: any) => !operation.opsReady
      );

      this.operationsReady = this.operations$.filter(
        (operation: any) =>
          operation.opsReady && operation.status !== 'awaiting collection'
      );

      let filterArray = this.getLocalStorage();
      if (filterArray) {
        this.filterItemsArray = filterArray;
        this.filteredOperations = this.filterOperations(filterArray);
      } else {
        this.filteredOperations = this.filterOperations([]);
      }
      this.getAllcompanies();
      this.getAllAM();

      this.filterReadyOperations();
      this.fillOpListFilters();
    });

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {
        this.getRoleUser();
        if (this.sendEmail) {
          let data;
          data = {
            type: this.dataEmail.type,
            isExternal: this.dataEmail.isExternal,
            currency: this.dataEmail.currency,
            cc: this.dataEmail.cc,
            file: this.dataEmail.file,
            notes: this.dataEmail.notes,
            to: this.dataEmail.to,
            observations: obs,
          };
          if (
            (this.loggedInUserRole === Roles.ADMIN ||
              this.loggedInUserRole === Roles.SUPER_ADMIN ||
              this.loggedInUserRole === Roles.ACCOUNT_MANAGER_SUPERVISOR) &&
            this.router.url === '/operations'
          ) {
            this.send(this.selectedInvoice, data);
          }
        }
        if (
          this.openModalSchedule &&
          (this.loggedInUserRole === Roles.ADMIN ||
            this.loggedInUserRole === Roles.SUPER_ADMIN ||
            this.loggedInUserRole === Roles.CLIENT_HAPPINESS)
        ) {
          this.putInvoiceForSale(this.selectedInvoice, this.scheduleInvoice);
        }
      });
  }

  getRoleUser() {
    this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserRole = role));
  }
  getFilterArray() {
    let filterArray = this.getLocalStorage();
    if (filterArray) {
      this.filterItemsArray = filterArray;
    }
  }
  setFilterOption() {
    if (this.profile?.role === 'account manager') {
      this.filtersOptions = filtersOptionsAM;
      this.attribute.options = this.filtersOptions;
    } else {
      this.filtersOptions = filtersOptions;
      this.attribute.options = this.filtersOptions;
    }
  }
  getAllAM() {
    let amArray = [];
    this.operations$.forEach((element) => {
      if (element.AccountManagerName) {
        let obj = {
          name: element.AccountManagerName.toLowerCase(),
        };
        amArray.push(obj);
      }
    });

    this.allAccountManagers = [
      ...new Map(
        amArray.map((item) => [item['name'], { name: item['name'] }])
      ).values(),
    ];
  }

  getAllcompanies() {
    let companiesArray = [];
    this.operations$.forEach((element) => {
      if (element.debtor) {
        let obj = {
          companyName: element.debtor.companyName?.toLowerCase(),
          companyRuc: element.debtor.companyRuc,
        };
        companiesArray.push(obj);
      }
      if (element.isConfirming) {
        if (element.supplier) {
          let obj = {
            companyName: element.supplier.companyName?.toLowerCase(),
            companyRuc: element.supplier.companyRuc,
          };
          companiesArray.push(obj);
        }
      } else {
        if (element.issuer?.companyName) {
          let obj = {
            companyName: element.issuer.companyName?.toLowerCase(),
            companyRuc: element.issuer.companyRuc,
          };
          companiesArray.push(obj);
        }
      }
    });

    this.allCompanies = [
      ...new Map(
        companiesArray.map((item) => [
          item['companyRuc'],
          { companyName: item['companyName'], companyRuc: item.companyRuc },
        ])
      ).values(),
    ];
  }

  openDetailPayer(operation) {
    if (this.profile.role === Roles.RISK_ANALYST) {
      this.router.navigate([]).then((result) => {
        window.open('/risk-debtors/' + operation?.debtor?._id, '_blank');
      });
    }
  }
  openDetail(operation) {
    this.router.navigate([]).then((result) => {
      window.open('/invoices/' + operation._id, '_blank');
    });
  }
  gotoSlack(operation) {
    let url = operation.slackDirectUrl;
    window.open(url, '_blank');
  }

  gotoPipeDrive(operation) {
    let url = operation.pipedriveDirectUrl;
    window.open(url, '_blank');
  }

  openModal(inputEntity) {
    const entity = lodash.cloneDeep(inputEntity);
    // this.addCommentTootlip(entity);
    let checklist, checklistSupplier, data;
    if (entity.type === 'documents' && entity.invoice?.isConfirming) {
      checklistSupplier = [
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'pending' && c.subType == 'provider'
          ),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'denied' && c.subType == 'provider'
          ),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) =>
              (c.status == 'approved' || c.status == 'exception') &&
              c.subType == 'provider'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
      checklist = [
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'pending' && c.subType == 'user'
          ),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'denied' && c.subType == 'user'
          ),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) =>
              (c.status == 'approved' || c.status == 'exception') &&
              c.subType == 'user'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
    } else {
      // this.addCavaliTooltip(entity);

      checklist = [
        {
          items: entity.subChecks.filter((c) => c.status == 'pending'),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter((c) => c.status == 'denied'),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'approved' || c.status == 'exception'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
    }

    data = { ...entity, checklist, checklistSupplier };

    let maxHeight;
    if (entity.type === 'documents' && !entity.invoice.isConfirming) {
      maxHeight = '80vh';
    } else {
      maxHeight = '100vh';
    }
    const dialogRef = this.dialog.open(ChecklistModalComponent, {
      maxHeight: maxHeight,
      width: '1200px',
      data,
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addCommentTootlip(entity) {
    for (let index = 0; index < entity.subChecks.length; index++) {
      if (entity.subChecks[index].comment) {
        entity.subChecks[index].tooltipText = entity.subChecks[index].comment;
      }
    }
  }

  addCavaliTooltip(entity) {
    if (entity.type === 'cavali') {
      for (let index = 0; index < entity.subChecks.length; index++) {
        if (entity.subChecks[index].type == 'compliance') {
          entity.subChecks[index].tooltipText = entity.invoice.physicalInvoices
            .map((p) => {
              let status = '---';

              if (p.cavaliStatus == 'in progress') {
                status = 'Sin respuesta';
              }

              if (p.cavaliPayerResponse) {
                switch (p.cavaliPayerResponse) {
                  case 'express compliance':
                    status = 'Conformidad Expresa';
                    break;
                  case 'rectified compliance':
                    status = 'Subsanada';
                    break;
                  case 'disagreement':
                  case 'rectified disagreement':
                    status = 'Disconformidad';
                    break;
                  default:
                    status = 'Conformidad presunta';
                    break;
                }
              }

              return `• ${p.code} \xa0\ \xa0\ (${status})`;
            })
            .join('\n');
        }
      }
    }
  }

  filterUpdates(filters) {
    this.filteredOperations = this.filterOperations(filters);
  }

  filterOperations(filters) {
    let filteredOperations = lodash
      .cloneDeep(this.operations$)
      .filter((operation: any) => !operation.opsReady);
    this.setLocalStorage(filters);

    filters.forEach((filter) => {
      if (
        filter.operator &&
        filter.value &&
        (typeof filter.value == 'object' || typeof filter.value == 'string')
      ) {
        switch (filter.attribute) {
          case 'debtor_company':
            if (filter.operator == 'contains') {
              if (
                filter.value?.companyName !== undefined &&
                filter.value?.companyRuc !== undefined
              ) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.debtor?.companyRuc
                    ?.toLowerCase()
                    .includes(filter.value.companyRuc.toLowerCase())
                );
              } else if (filter.value?.companyName !== undefined) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.debtor?.companyName
                    ?.toLowerCase()
                    .includes(filter.value.companyName.toLowerCase())
                );
              } else {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.debtor?.companyRuc
                    ?.toLowerCase()
                    .includes(filter.value.companyRuc.toLowerCase())
                );
              }
            } else if (filter.operator == 'not_contains') {
              if (
                filter.value?.companyName !== undefined &&
                filter.value?.companyRuc !== undefined
              ) {
                filteredOperations = filteredOperations.filter(
                  (op) =>
                    !op?.debtor?.companyRuc
                      ?.toLowerCase()
                      .includes(filter.value.companyRuc.toLowerCase())
                );
              } else if (filter.value?.companyName !== undefined) {
                filteredOperations = filteredOperations.filter(
                  (op) =>
                    !op?.debtor?.companyName
                      ?.toLowerCase()
                      .includes(filter.value.companyName.toLowerCase())
                );
              } else {
                filteredOperations = filteredOperations.filter(
                  (op) =>
                    !op?.debtor?.companyRuc
                      ?.toLowerCase()
                      .includes(filter.value.companyRuc.toLowerCase())
                );
              }
            } else if (filter.operator == 'equal') {
              if (
                filter.value?.companyName !== undefined &&
                filter.value?.companyRuc !== undefined
              ) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.debtor?.companyRuc
                    ?.toLowerCase()
                    .includes(filter.value.companyRuc.toLowerCase())
                );
              } else if (filter.value.companyName !== undefined) {
                filteredOperations = filteredOperations.filter(
                  (op) =>
                    op?.debtor?.companyName?.toLowerCase() ==
                    filter.value.companyName.toLowerCase()
                );
              } else {
                filteredOperations = filteredOperations.filter(
                  (op) =>
                    op?.debtor?.companyRuc?.toLowerCase() ==
                    filter.value.companyRuc.toLowerCase()
                );
              }
            }
            break;
          case 'supplier_company':
            if (filter.operator == 'contains') {
              if (
                filter.value?.companyName !== undefined &&
                filter.value?.companyRuc !== undefined
              ) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? op?.supplier?.companyRuc.includes(
                        filter.value.companyRuc.toLowerCase()
                      )
                    : op?.issuer?.companyRuc.includes(
                        filter.value.companyRuc.toLowerCase()
                      )
                );
              } else if (filter.value?.companyName !== undefined) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? op?.supplier?.companyName
                        ?.toLowerCase()
                        .includes(filter.value.companyName.toLowerCase())
                    : op?.issuer?.companyName
                        ?.toLowerCase()
                        .includes(filter.value.companyName.toLowerCase())
                );
              } else {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? op?.supplier?.companyRuc
                        ?.toLowerCase()
                        .includes(filter.value.companyRuc.toLowerCase())
                    : op?.issuer?.companyRuc
                        ?.toLowerCase()
                        .includes(filter.value.companyRuc.toLowerCase())
                );
              }
            } else if (filter.operator == 'not_contains') {
              if (
                filter.value?.companyName !== undefined &&
                filter.value?.companyRuc !== undefined
              ) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? !op?.supplier?.companyRuc.includes(
                        filter.value.companyRuc.toLowerCase()
                      )
                    : !op?.issuer?.companyRuc.includes(
                        filter.value.companyRuc.toLowerCase()
                      )
                );
              } else if (filter.value?.companyName !== undefined) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? !op?.supplier?.companyName
                        ?.toLowerCase()
                        .includes(filter.value.companyName.toLowerCase())
                    : !op?.issuer?.companyName
                        ?.toLowerCase()
                        .includes(filter.value.companyName.toLowerCase())
                );
              } else {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? !op?.supplier?.companyRuc
                        ?.toLowerCase()
                        .includes(filter.value.companyRuc.toLowerCase())
                    : !op?.issuer?.companyRuc
                        ?.toLowerCase()
                        .includes(filter.value.companyRuc.toLowerCase())
                );
              }
            } else if (filter.operator == 'equal') {
              if (
                filter.value?.companyName !== undefined &&
                filter.value?.companyRuc !== undefined
              ) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? op?.supplier?.companyRuc.includes(
                        filter.value.companyRuc.toLowerCase()
                      )
                    : op?.issuer?.companyRuc.includes(
                        filter.value.companyRuc.toLowerCase()
                      )
                );
              } else if (filter.value?.companyName !== undefined) {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? op?.supplier?.companyName?.toLowerCase() ==
                      filter.value.toLowerCase()
                    : op?.issuer?.companyName?.toLowerCase() ==
                      filter.value.toLowerCase()
                );
              } else {
                filteredOperations = filteredOperations.filter((op) =>
                  op?.isConfirming
                    ? op?.supplier?.companyRuc?.toLowerCase() ==
                      filter.value.toLowerCase()
                    : op?.issuer?.companyRuc?.toLowerCase() ==
                      filter.value.toLowerCase()
                );
              }
            }
            break;
          case 'account_manager':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.AccountManagerName?.toLowerCase() ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.AccountManagerName?.toLowerCase() !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'currencyType':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) => op?.currency == filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) => op?.currency != filter.value.toLowerCase()
              );
            }
            break;
          case 'invoice_code':
            if (filter.operator == 'contains') {
              filteredOperations = filteredOperations.filter((op) =>
                op?.physicalInvoices?.some((p) =>
                  p.code?.toLowerCase()?.includes(filter.value.toLowerCase())
                )
              );
            } else if (filter.operator == 'equal') {
              filteredOperations = filteredOperations.filter((op) =>
                op?.physicalInvoices?.some(
                  (p) => p.code?.toLowerCase() == filter.value.toLowerCase()
                )
              );
            }
            break;
          case 'duration':
            if (filter.operator == 'equal') {
              filteredOperations = filteredOperations.filter(
                (op) => Number(op?.minimumDuration) == Number(filter.value)
              );
            } else if (filter.operator == 'less_than') {
              filteredOperations = filteredOperations.filter(
                (op) => Number(op?.minimumDuration) < Number(filter.value)
              );
            } else if (filter.operator == 'greater_than') {
              filteredOperations = filteredOperations.filter(
                (op) => Number(op?.minimumDuration) > Number(filter.value)
              );
            }
            break;
          case 'net_amount':
            if (filter.operator == 'equal') {
              filteredOperations = filteredOperations.filter(
                (op) => Number(op?.netAmount) == Number(filter.value)
              );
            } else if (filter.operator == 'less_than') {
              filteredOperations = filteredOperations.filter(
                (op) => Number(op?.netAmount) < Number(filter.value)
              );
            } else if (filter.operator == 'greater_than') {
              filteredOperations = filteredOperations.filter(
                (op) => Number(op?.netAmount) > Number(filter.value)
              );
            }
            break;
          case 'operationType':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter((op) =>
                op?.isConfirming
                  ? 'confirming' == filter.value.toLowerCase()
                  : 'factoring' == filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter((op) =>
                op?.isConfirming
                  ? 'confirming' != filter.value.toLowerCase()
                  : 'factoring' != filter.value.toLowerCase()
              );
            }
            break;
          case 'processType':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter((op) =>
                op?.isSunatProcess
                  ? 'sunat' == filter.value.toLowerCase()
                  : 'cavali' == filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter((op) =>
                op?.isSunatProcess
                  ? 'sunat' != filter.value.toLowerCase()
                  : 'cavali' != filter.value.toLowerCase()
              );
            }
            break;
          case 'documents':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'documents')?.status ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'documents')?.status !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'conditions':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'conditions')?.status ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'conditions')?.status !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'risk':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'risk')?.status ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'risk')?.status !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'tea':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'tea')?.status ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'tea')?.status !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'cavali':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'cavali')?.status ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'cavali')?.status !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'warranty':
            if (filter.operator == 'is_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'warranty')?.status ==
                  filter.value.toLowerCase()
              );
            } else if (filter.operator == 'not_equal') {
              filteredOperations = filteredOperations.filter(
                (op) =>
                  op?.checklist?.find((c) => c.type == 'warranty')?.status !=
                  filter.value.toLowerCase()
              );
            }
            break;
          case 'estimatedToTransfer':
            if (filter.operator == 'is_equal') {
              if (filter.value === 'yes') {
                filteredOperations = filteredOperations.filter(
                  (op) => op?.estimatedTransferDate && !op?.advanceTransferAt
                );
              } else {
                filteredOperations = filteredOperations.filter(
                  (op) => !op.estimatedTransferDate && !op?.advanceTransferAt
                );
              }
            } else if (filter.operator == 'not_equal') {
              if (filter.value === 'yes') {
                filteredOperations = filteredOperations.filter(
                  (op) => !op?.estimatedTransferDate && !op?.advanceTransferAt
                );
              } else {
                filteredOperations = filteredOperations.filter(
                  (op) => op.estimatedTransferDate && !op?.advanceTransferAt
                );
              }
            }
            break;
          default:
            break;
        }
      }
    });
    return filteredOperations;
  }

  setLocalStorage(filters) {
    if (filters.length > 0) {
      localStorage.setItem('operations_filters', JSON.stringify(filters));
    }

    if (filters.length === 0) {
      localStorage.removeItem('operations_filters');
    }
  }

  getLocalStorage() {
    return JSON.parse(localStorage.getItem('operations_filters'));
  }

  filterOptionsChanges($event) {
    this.filterOptions = $event;

    this.filterReadyOperations();
    this.setOpListButtonFilters();
  }

  filterInputChanges($event) {
    if(!$event){
      localStorage.removeItem('filteredInput');
    }
    this.filteredInput = $event;

    this.filterReadyOperations();
    this.setOpListButtonFilters();
  }

  filterReadyOperations() {
    this.operationsReady = lodash
      ?.cloneDeep(this.operations$)
      ?.filter(
        (operation: any) =>
          operation.opsReady && operation.status !== 'awaiting collection'
      );

    let buttonFiltters = this.filterOptions?.filter((op) => op.checked == true);

    if (buttonFiltters?.length > 0) {
      buttonFiltters.forEach((filter) => {
        this.operationsReady = this.buttonFilters(
          filter.value,
          this.operationsReady
        );
      });
      // let currency = buttonFiltters.map(item => (item.value === 'usd' && item.value === 'pen'))
      // if(currency){
      //   buttonFiltters.map(item => (item.value === 'usd' && item.value === 'pen'))
      //   console.log(buttonFiltters)
      //   buttonFiltters.forEach((filter) => {
      //     this.operationsReady = this.buttonFilters(
      //       filter.value,
      //       this.operationsReady
      //     );
      //   });
      // }else{
      //   console.log(buttonFiltters)
    }
    if (this.filteredInput?.companyRuc != undefined) {
      this.operationsReady = this.inputFiters(this.operationsReady);
    }

    this.setOpListButtonFilters();
  }

  fillOpListFilters() {
    this.inputLable = 'Busca por nombre o ruc';

    let inputFilters = this.getOpListInputFilters();

    if (inputFilters && Object.keys(inputFilters).length) {
      this.filteredInput = inputFilters;
    }

    this.filterOptions = this.getFiltersForRoles();

    let buttonFilters = this.getOpListButtonFilters();

    if (buttonFilters) {
      this.filterOptions = buttonFilters;
    }
    let companiesArray = [];
    let inputops = this.operationsReady.forEach((element) => {
      if (element.debtor) {
        let obj = {
          companyName: element.debtor.companyName?.toLowerCase(),
          companyRuc: element.debtor.companyRuc,
        };
        companiesArray.push(obj);
      }
      if (element.isConfirming) {
        if (element.supplier) {
          let obj = {
            companyName: element.supplier.companyName?.toLowerCase(),
            companyRuc: element.supplier.companyRuc,
          };
          companiesArray.push(obj);
        }
      } else {
        if (element.issuer?.companyName) {
          let obj = {
            companyName: element.issuer.companyName?.toLowerCase(),
            companyRuc: element.issuer.companyRuc,
          };
          companiesArray.push(obj);
        }
      }
    });
    // let inputops = this.operationsReady
    //   .map((op) => {
    //     return {
    //       companyName: op.debtor.companyName,
    //       companyRuc: op.debtor.companyRuc,
    //     };
    //   })
    //   .filter(
    //     (value, index, self) =>
    //       index === self.findIndex((t) => t.companyRuc === value.companyRuc)
    //   );

    this.inputOptions = [
      ...new Map(
        companiesArray.map((item) => [
          item['companyRuc'],
          { companyName: item['companyName'], companyRuc: item.companyRuc },
        ])
      ).values(),
    ];

    // console.log(this.inputOptions)
    // this.inputOptions = inputops;

    this.filterReadyOperations();
  }

  getOpListButtonFilters() {
    let storedData = localStorage.getItem('filterOptions');

    return storedData != undefined ? JSON.parse(storedData) : null;
  }

  getOpListInputFilters() {
    let storedData = localStorage.getItem('filteredInput');

    return storedData != undefined ? JSON.parse(storedData) : null;
  }

  setOpListButtonFilters() {
    if (this.filterOptions) {
      localStorage.setItem('filterOptions', JSON.stringify(this.filterOptions));
    }

    if (this.filteredInput) {
      localStorage.setItem('filteredInput', JSON.stringify(this.filteredInput));
    }
  }

  buttonFilters(filterValue, opList) {
    switch (filterValue) {
      case 'Falta enviar correo de desembolso':
        return opList.filter(
          (op) =>
            op.emailLog?.filter((e) => e.type === 'DESEMBOLSO').length == 0
        );
      case 'Falta desembolsar':
        return opList.filter(
          (op) =>
            !op.hasOwnProperty('advancePaymentDate') &&
            op.emailLog?.filter((e) => e.type === 'DESEMBOLSO').length > 0
        );
      case 'Desembolsado':
        return opList.filter((op) => op.hasOwnProperty('advancePaymentDate'));
      case 'Falta poner en venta':
        return opList.filter((op) => op.status == 'pending confirmation');
      case 'Invertido mayor al 50%':
        return opList.filter(
          (op) => parseFloat(op.availableBalancePercentage) < 50
        );
      case 'pen':
        return opList.filter((op) => op.currency == 'pen');
      case 'usd':
        return opList.filter((op) => op.currency == 'usd');
      case 'estimatedToTransfer':
        return opList.filter(
          (op) => op.estimatedTransferDate && !op?.advanceTransferAt
        );
      default:
        break;
    }
  }

  inputFiters(opList) {
    return opList.filter(
      (op) =>
        op?.debtor?.companyRuc == this.filteredInput.companyRuc ||
        op?.issuer?.companyRuc == this.filteredInput.companyRuc ||
        op?.supplier?.companyRuc == this.filteredInput.companyRuc
    );
  }

  getFiltersForRoles() {
    if (
      this.profile?.role === 'admin' ||
      this.profile?.role === 'super admin' ||
      this.profile?.role === 'risk analyst'
    ) {
      return [
        {
          text: 'Falta enviar correo de desembolso',
          value: 'Falta enviar correo de desembolso',
          checked: false,
        },
        {
          text: 'Falta desembolsar',
          value: 'Falta desembolsar',
          checked: false,
        },
        { text: 'Desembolsado', value: 'Desembolsado', checked: false },
        {
          text: 'Falta poner en venta',
          value: 'Falta poner en venta',
          checked: false,
        },
        {
          text: 'Invertido mayor al 50%',
          value: 'Invertido mayor al 50%',
          checked: false,
        },
        {
          text: 'USD',
          value: 'usd',
          checked: false,
        },
        {
          text: 'PEN',
          value: 'pen',
          checked: false,
        },
        {
          text: 'Estimado para hoy',
          value: 'estimatedToTransfer',
          checked: false,
        },
      ];
    } else if (this.profile?.role === 'client happiness') {
      return [
        { text: 'Desembolsado', value: 'Desembolsado', checked: false },
        {
          text: 'Falta poner en venta',
          value: 'Falta poner en venta',
          checked: false,
        },
        {
          text: 'USD',
          value: 'usd',
          checked: false,
        },
        {
          text: 'PEN',
          value: 'pen',
          checked: false,
        },
        {
          text: 'Estimado para hoy',
          value: 'estimatedToTransfer',
          checked: false,
        },
      ];
    } else if (
      this.profile?.role === 'account manager' ||
      this.profile?.role === 'account manager supervisor'
    ) {
      return [
        {
          text: 'Falta enviar correo de desembolso',
          value: 'Falta enviar correo de desembolso',
          checked: false,
        },
        {
          text: 'Falta desembolsar',
          value: 'Falta desembolsar',
          checked: false,
        },
        {
          text: 'Invertido mayor al 50%',
          value: 'Invertido mayor al 50%',
          checked: false,
        },
        {
          text: 'USD',
          value: 'usd',
          checked: false,
        },
        {
          text: 'PEN',
          value: 'pen',
          checked: false,
        },
        {
          text: 'Estimado para hoy',
          value: 'estimatedToTransfer',
          checked: false,
        },
      ];
    }
  }

  newAction(event) {
    let action = event?.action;
    let invoice = event?.entity;
    this.selectedInvoice = invoice;
    switch (action) {
      case 'schedule_invoice':
        this.putInvoiceForSale(invoice);
        break;
      case 'cancel_sale':
        this.cancelSale(invoice);
        break;
      case 'close_invoice':
        this.closeSale(invoice);
        break;
      case 'send_email':
        this.send(invoice);
        break;
      case 'create_investment':
        this.navigateToInvest(invoice);
        break;
      case 'pay_advance':
        this.payAdvanceToClient(invoice);
        break;

      default:
        break;
    }
  }

  putInvoiceForSale(invoice, result?) {
    let dialogRef = this.openDialogHelper(
      InvoiceForSaleDialogComponent,
      invoice,
      false
    );
    this.connectDialogListeners(dialogRef, (result: any) => {
      if (result) {
        let invoicesTelgram = [invoice];

        if (result.selectedInvoices && result.selectedInvoices.length)
          invoicesTelgram = invoicesTelgram.concat(result.selectedInvoices);

        this.service
          .getTelegramMessage(invoicesTelgram, result.onSaleTime)
          .subscribe((data: any) => {
            this.scheduleInvoice = result;
            this.openModalSchedule = true;

            result.telegramMessage = data.telegram;

            if (result.event === 'Cancel') {
              this.deactivateModalSchedule();
              return;
            }

            const callbackSchedule = () => {
              // this.reFetchInvoice();
              this.deactivateModalSchedule();
            };

            this.overlayService.startConfirmation(
              {
                status: 'for sale',
                _id: invoice._id,
                onSaleDate: result.onSaleDate,
                onSaleSlot: result.onSaleSlot,
                invoiceIds: result.selectedInvoices?.map((inv) => {
                  return { _id: inv._id };
                }),
                telegramMessage: result.telegramMessage,
              },
              {
                component: ConfirmInvoiceForSaleComponent,
                entity: { ...invoice, ...result },
              },
              'details',
              'edit',
              null,
              InvoicesActions.Saving,
              `operations`,
              InvoiceSelectors.savingSuccessStateById,
              {
                onSuccess: {
                  showCheck: true,
                  title: 'La factura ha sido enviada a las oportunidades.',
                },
                onConfirm: {
                  title: '¿Estás seguro?',
                },
              },
              callbackSchedule.bind(this)
            );
          });
      } else {
        this.openModalSchedule = false;
      }
    });
  }

  cancelSale(invoice) {
    this.overlayService.startConfirmation(
      { status: 'pending confirmation', _id: invoice?._id },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `operations`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha retirado',
        },
      }
      //this.reFetchInvoice.bind(this)
    );
  }

  closeSale(invoice) {
    const confirmationDialog = this.dialog.open(NewConfirmationModalComponent, {
      maxWidth: '444px',
      maxHeight: '100vh',
      height: 'auto',
      autoFocus: false,
      disableClose: true,
      data: {
        question: `¿Estás seguro que quieres cerrar la venta?`,
        warning:
          invoice.availableBalancePercentage != '0.00'
            ? `Recuerda que el monto de inversión faltante será <span style="font-weight:400;">asumido por Finsmart</span> y esta operación será retirada de la venta.`
            : 'Esta operación será retirada de la venta.',
      },
    });

    confirmationDialog.afterClosed().subscribe((res: any) => {
      if (res) {
        this.spinner.show();

        this.service
          .update({
            _id: invoice._id,
            status: InvoiceStatus['Awaiting Collection'],
          })
          .subscribe(
            (response) => {
              this.spinner.hide();

              this.toaster.open({
                text: response
                  ? 'Se retiró la factura de la venta'
                  : 'Hubo un error, intentarlo más tarde',
                caption: response ? 'Se cerró la venta' : 'Error',
                duration: 4000,
                autoClose: true,
                position: 'top-right',
                type: response ? 'success' : 'danger',
                preventDuplicates: true,
              });
            },
            (error) => {
              this.toaster.open({
                text: 'Hubo un error, intentarlo más tarde',
                caption: 'Error',
                autoClose: false,
                position: 'top-right',
                type: 'danger',
                preventDuplicates: true,
              });
            }
          );
      }
    });
  }

  navigateToInvest(invoice: any) {
    const navigationExtra: NavigationExtras = {
      state: { invoice: invoice, urlRedirect: '/operations' },
    };

    this.router.navigate(['/financial-transactions/invest'], navigationExtra);
  }

  payAdvanceToClient(invoice) {
    if (
      this.isInvoiceReady(invoice) &&
      !invoice.advancePaymentDate &&
      invoice.emailLog.some((e: any) => e.type === 'DESEMBOLSO')
    ) {
      let dialogRef = this.openDialogHelper(PayClientAdvanceDialogComponent);
      this.isAdvanceModalOpened = true;
      this.connectDialogListeners(dialogRef, (result) => {
        this.isAdvanceModalOpened = false;

        if (result) {
          const confirmationDialog = this.dialog.open(
            NewConfirmationModalComponent,
            {
              maxWidth: '444px',
              maxHeight: '100vh',
              height: 'auto',
              autoFocus: false,
              disableClose: true,
              data: {
                question: `¿Estás seguro que la transferencia de la operación ya fue realizada el ${moment(
                  result.advancePaymentDate,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY')}?`,
                warning: `Esta acción será notificada al cliente por Whatsapp y en la plataforma.`,
              },
            }
          );

          confirmationDialog.afterClosed().subscribe((res: any) => {
            if (res) {
              this.spinner.show();

              this.service
                .update({
                  _id: invoice._id,
                  advancePaymentDate: result.advancePaymentDate,
                })
                .subscribe(
                  (response) => {
                    this.spinner.hide();
                    this.toaster.open({
                      text: response
                        ? 'Se envió el mensaje al cliente'
                        : 'Hubo un error, intentarlo más tarde',
                      caption: response ? 'Adelanto pagado' : 'Error',
                      duration: 4000,
                      autoClose: true,
                      position: 'top-right',
                      type: response ? 'success' : 'danger',
                      preventDuplicates: true,
                    });
                  },
                  (error) => {
                    this.spinner.hide();
                    if (error?.error == 'INVOICE.IS_ALREADY_COLLECTED') {
                      this.toaster.open({
                        text: 'El adelanto de la operación ya se encontraba realizado.',
                        caption: 'Error',
                        autoClose: false,
                        position: 'top-right',
                        type: 'danger',
                        preventDuplicates: true,
                      });
                    } else {
                      this.toaster.open({
                        text: 'Hubo un error, intentarlo más tarde.',
                        caption: 'Error',
                        autoClose: false,
                        position: 'top-right',
                        type: 'danger',
                        preventDuplicates: true,
                      });
                    }
                  }
                );
            } else {
              this.payAdvanceToClient(invoice);
            }
          });
        }
      });
    }
  }

  isInvoiceReady(invoice) {
    return invoice.status === 'for sale' ||
      invoice.status === 'sale closed' ||
      invoice.status === 'partially sold'
      ? true
      : invoice.checklist &&
          invoice.status === 'pending confirmation' &&
          invoice.checklist.every(
            (c) => c.status == 'approved' || c.status == 'exception'
          ) &&
          invoice.physicalInvoices.every((inv) =>
            inv.cavaliPayerResponse === 'rectified compliance'
              ? inv.creditNote
              : true
          ) &&
          invoice.evaluation.status !== 'rejected' &&
          invoice.evaluation.lastEvaluationDays <= 60 &&
          invoice.evaluation.hasOwnProperty('status') &&
          !invoice.debtor?.isObserve &&
          (invoice.isConfirming
            ? Number(invoice.reservationPercentage) == 0 ||
              invoice.evaluationDays >= 0
            : invoice.evaluationDays >= 0);
  }
  send(invoice, data2?) {
    let data;
    let emailData;
    const desembolsoFlag = invoice?.emailLog.some(
      (email) => email.type === 'DESEMBOLSO'
    );
    if (desembolsoFlag) {
      //resend
      emailData = invoice.emailSent?.filter(
        (email) => email.type === 'DESEMBOLSO'
      );
    } else {
      //send
      emailData = invoice.emailReady?.filter(
        (email) => email.type === 'DESEMBOLSO'
      );
    }

    data = {
      type: 'DESEMBOLSO',
      isExternal: false,
      _id: invoice._id,
      currency: invoice.currency,
      to: emailData[0]?.to?.toString(),
      cc: emailData[0]?.cc?.toString(),
      subject: emailData[0]?.subject,
      observations: emailData[0]?.observations,
      file: emailData[0]?.file,
      notes: emailData[0]?.notes,
    };

    let dialogRef;

    dialogRef = this.openDialogHelper(EmailBoDialogComponent, {
      data: this.dataEmail ? this.dataEmail : data,
    });

    dialogRef.beforeClosed().subscribe((res) => {
      this.dataEmail = '';
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        if (result.event === 'Cancel') {
          this.dataEmail = '';
          return;
        }

        if (this.dataEmail) {
          this.dataEmail.cc = result.cc;
          this.dataEmail.to = result.to;
        }

        this.sendEmail = true;
        this.dataEmail = this.dataEmail ? this.dataEmail : result;
        const callbackEmail = () => {
          this.resetEmailSend();
        };

        result.isConfirming = invoice.isConfirming;
        this.overlayService.startConfirmation(
          { result: result, _id: invoice._id },
          {
            component: EmailPreviewComponent,
            entity: result,
          },
          'details',
          'edit',
          null,
          InvoicesActions.SavingEmail,
          `operations`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Correo enviado!',
            },
            onConfirm: {
              title: EmailType['DESEMBOLSO'],
            },
          },
          callbackEmail.bind(this),
          '60%',
          'Enviar',
          'Regresar'
        );
      }
    });
  }

  // reFetchInvoice() {
  //   if (this.loggedInUserRole === Roles.CLIENT_HAPPINESS) {
  //     this.openModalComment = false;
  //   }
  //   this.fetchInvoice(this.selectedInvoiceId);
  // }

  openDialogHelper(dialogComp, data?, autoFocus?, width?) {
    let focus = autoFocus == false ? false : true;
    let fixedWidth = width ?? '';
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      width: fixedWidth,
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: focus,
      data: data,
    });

    return dialogRef;
  }

  resetEmailSend() {
    this.sendEmail = !this.sendEmail;
    this.dataEmail = '';
    // setTimeout(() => {
    //   this.store.dispatch(InvoicesActions.LoadAllOperations());
    // }, 200);
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  deactivateModalSchedule() {
    this.openModalSchedule = false;
  }

  setTransferDate(invoice) {
    this.service
      .setEstimatedTranferDate(
        invoice,
        invoice?.estimatedTransferDate ? false : true
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.toaster.open({
            text: response
              ? invoice?.estimatedTransferDate
                ? 'Se despriorizó la operación'
                : 'Se priorizó la operación'
              : 'Hubo un error, intentarlo más tarde',
            caption: response ? 'Priorizar operación' : 'Error',
            duration: 4000,
            autoClose: true,
            position: 'top-right',
            type: response ? 'success' : 'danger',
            preventDuplicates: true,
          });
        },
        (error) => {
          this.spinner.hide();
          this.toaster.open({
            text: 'Hubo un error, intentarlo más tarde.',
            caption: 'Error',
            autoClose: false,
            position: 'top-right',
            type: 'danger',
            preventDuplicates: true,
          });
        }
      );
  }
}
