import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-evaluation-information-popup",
  templateUrl: "./evaluation-information-popup.component.html",
  styleUrls: ["./evaluation-information-popup.component.scss"],
})
export class EvaluationInformationPopupComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EvaluationInformationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public evaluation: any
  ) {}

  ngOnInit(): void {
    console.log("Evaluation", this.evaluation);
  }

  close() {
    this.dialogRef.close(false);
  }

  stringToList(value: string) {
    return value
      .trim()
      .split(",")
      .map((item) => item.trim());
  }
}
