import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { emailsValidator } from 'src/app/shared/validators/multiple-emails.validator';

@Component({
  selector: 'app-email-bo-dialog',
  templateUrl: './email-bo-dialog.component.html',
  styleUrls: ['./email-bo-dialog.component.scss'],
})
export class EmailBoDialogComponent implements OnInit {
  @Input() type;
  @Input() entity;
  form: FormGroup;
  email = {
    cc: undefined,
    to: undefined,
    isExternal: undefined,
    type: undefined,
    _id: undefined,
    notes: undefined,
    file: undefined,
    currency: undefined,
    subject: undefined,
    payerResponse: undefined,
  };
  title;
  error;

  sunatResponse = [
    {
      text: 'Expresa',
      value: 'express compliance',
    },
    {
      text: 'Presunta',
      value: 'presumption compliance',
    },
    {
      text: 'Sin respuesta',
      value: 'in progress',
    },
    {
      text: 'Disconformidad',
      value: 'disagreement',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<EmailBoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    let formVars: any = {
      cc: ['', [Validators.required, emailsValidator]],
      payerResponse: [''],
      notes: [''],
      file: [''],
      subject: [''],
    };

    if (
      this.data?.data.type !== 'OPERATION_START' &&
      this.data?.data.type !== 'CONFORMIDAD_CAVALI'
    )
      formVars.to = ['', [Validators.required, emailsValidator]];

    this.form = this.formBuilder.group(formVars);

    if (this.data.data.cc || this.data.data.to) {
      this.form.get('cc').setValue(this.data.data.cc);
      if (
        this.data?.data.type !== 'OPERATION_START' &&
        this.data?.data.type !== 'CONFORMIDAD_CAVALI'
      )
        this.form.get('to').setValue(this.data.data.to);
      this.form.get('subject').setValue(this.data.data.subject);
      this.form.get('file').setValue(this.data.data?.file);
      this.form.get('notes').setValue(this.data.data?.notes);
    }

    this.getTitle();
    if (this.data.data.type === 'OPERATION_START') {
      this.form.get('payerResponse').setValidators([Validators.required]);
      this.form.get('payerResponse').updateValueAndValidity();
    }

    if (
      this.data.data.type === 'OPERATION_START' ||
      this.data.data.type === 'CONFORMIDAD_CAVALI'
    ) {
      if (this.data.data.to.split(',').length < 2) {
        this.error =
          'Se requieren por lo menos 2 contactos validados por Riesgos.';
      }
    }
  }

  getTitle() {
    switch (this.data.data.type) {
      case 'DESEMBOLSO':
        this.title = 'Desembolso';
        break;

      case 'CONFIRMACION_PAGO_CLIENTE':
        this.title = 'Confirmación de Pago al Cliente';
        break;

      case 'CONFORMIDAD_CAVALI':
        this.title = 'Conformidad CAVALI';
        break;

      case 'OPERATION_START':
        this.title = 'Conformidad SUNAT';
        break;

      case 'ACEPTACION_CONDICIONES':
        this.title = 'Aceptación de condiciones';
        break;

      case 'CONDITIONS_ACCEPTED':
        this.title = 'Condiciones aceptadas';
        break;

      case 'DEVOLUCION_SALDO':
        this.title = 'Devolución de Saldo';
        break;
      case 'CONFIGURATION_NOTIFICATION':
        this.title = 'Factura configurada';
        break;
      case 'INVOICE_CHANGE':
        this.title = 'Pagador cambió datos';
        break;

      default:
        break;
    }
  }

  onSubmit() {
    if (!this.error) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();

      if (this.form.valid) {
        this.email.cc = this.form.value.cc;
        this.email.to =
          this.data?.data.type === 'OPERATION_START' ||
          this.data?.data.type === 'CONFORMIDAD_CAVALI'
            ? this.data.data.to
            : this.form.value.to;
        this.email.type = this.data.data.type;
        this.email.isExternal = this.data.data.isExternal;
        this.email.currency = this.data.data.currency;
        this.email._id = this.data.data._id;
        this.email.subject = this.data.data.subject;
        this.email.payerResponse = this.form.value.payerResponse;

        if (!this.data.data.isExternal) {
          this.email.notes = this.form.value.notes;
          this.email.file = this.form.value.file;
        }
        this.dialogRef.close(this.email);
      }
    }
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
