<div class="container">
  <div class="title">
    <lib-text color="black" size="m" weight="heading" [content]="'Envío de correo'">
    </lib-text>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="header">
      <lib-choose-operations-email-header [debtor]="data.debtor" [issuer]="data.issuer" [subject]="subject"
        [isConfirming]="data.isConfirming" [isSunatProcess]="data.isSunatProcess">
        <div class="para-cc">
          <div *ngIf="
              data?.data.type === 'OPERATION_START' ||
              data?.data.type === 'CONFORMIDAD_CAVALI'
            " style="margin-bottom: 20px">
            <p class="tiny para-key">Para</p>
            <p class="break-line para-value">
              {{ data.data.to.split(",").join(", ") }}
            </p>
          </div>
          <div class="cc-input">
            <app-input formControlName="cc" autocomplete="off" type="text" label="CC"></app-input>
          </div>
        </div>
      </lib-choose-operations-email-header>
    </div>
    <div class="container-flex align-right">
      <p class="checkbox-title">Agregar todas al correo</p>
      <mat-checkbox (change)="selectAll()" [checked]="checkedAll" [indeterminate]="someComplete()">
      </mat-checkbox>
    </div>

    <lib-cavali-response-operation [checked]="op.selected" [invoice]="op" [operationLabel]="'Operación '+ (i+1)"
      (onChangeOperation)="changeOperation($event, op)" (onChangeCheck)="changeCheck($event)"
      *ngFor="let op of operations;let i=index;"></lib-cavali-response-operation>


    <div class="error" *ngIf="error">{{error}}</div>

    <div class="button-container button-direction-column">
      <span class="error" *ngIf="errorMessage">{{errorMessage}}</span>

      <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Continuar'" [type]="'secondary'">
      </fs-ui-button>
      <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
  </form>
</div>