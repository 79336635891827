<ng-container *allowedRoles="['investor']">
    <app-available-balance></app-available-balance>
    <hr />
</ng-container>
<div *allowedRoles="['admin','super admin']" class="one-line-admin-title">
    <app-page-title title="Facturas" description="Haz clic en la factura para ver los detalles.">
    </app-page-title>
    <div class="button-container">
        <fs-ui-button routerLink="create" [label]="'Nueva Factura'"></fs-ui-button>
    </div>
</div>

<div *allowedRoles="['client happiness']" class="one-line-admin-title">
    <app-page-title title="Facturas" description="Haz clic en la factura para ver los detalles.">
    </app-page-title>
</div>

<div *allowedRoles="['client']"
    style="margin-top: 40px;display: flex;justify-content: space-between; align-items: center;">
    <span class="new-title">Operaciones</span>

    <div style="display: flex;align-items: center;" class="pointer" routerLink="create">
        <span class="upload-button">Subir facturas</span>
        <img style="margin-left: 10px;" src="../../../../../../assets/icons/plus-sign.svg" width="20px" height="20px">
    </div>
</div>

<!-- <ng-container *ngIf="invoices as clientInvoices">
    <ng-container *ngIf="clientInvoices?.length > 0">
        <app-page-title *allowedRoles="['client']" title="Facturas"
            description="Haz clic en la factura para ver los detalles.">
        </app-page-title>
    </ng-container>
    <ng-container *ngIf="clientInvoices?.length < 1">
        <app-page-title *allowedRoles="['client']" title="Facturas"
            description="Aún no has subido ninguna factura. Haz clic en el botón para empezar.">
        </app-page-title>
    </ng-container>
</ng-container> -->

<app-page-title class="investor-title" *allowedRoles="['investor']" title="Oportunidades">
</app-page-title>

<div class="invoices-list">
    <ng-container *allowedRoles="['investor']">
        <app-invoice-item-investor *ngFor="let invoice of invoices$ | async" (click)="showInvest(invoice)"
            [entity]="invoice">
        </app-invoice-item-investor>
    </ng-container>

    <ng-container *allowedRoles="['client']">
        <mat-tab-group style="margin-top: 26px;" (selectedTabChange)="filterTabs($event);sendMixpanelEvent($event);"
            [(selectedIndex)]="selectedTab">
            <mat-tab>
                <ng-template mat-tab-label>
                    En proceso
                    <div *ngIf="totalDeniedChecksCount()" class="red-badge">
                        <span style="margin-left: 9px; display: inherit;" [matBadge]="totalDeniedChecksCount()"
                            matBadgeOverlap="false"></span>
                    </div>
                </ng-template>

                <table class="operation-table" *ngIf="invoices?.length > 0">
                    <thead>
                        <tr>
                            <th style="width: 22%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 0}, 'companyName')">Empresa
                                    <img style="margin-left: 2px;"
                                        *ngIf="inProcessPagination.activeSort === 'companyName'"
                                        [className]="inProcessPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 18%;">Facturas</th>
                            <th style="width: 16%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 0}, 'netAmount')">Monto neto
                                    <img style="margin-left: 2px" *ngIf="inProcessPagination.activeSort === 'netAmount'"
                                        [className]="inProcessPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 17%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 0}, 'paymentDate')">Fecha de
                                    pago
                                    <img style="margin-left: 2px;"
                                        *ngIf="inProcessPagination.activeSort === 'paymentDate'"
                                        [className]="inProcessPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 22%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 0}, 'progress')">Progreso
                                    <img style="margin-left: 2px;" *ngIf="inProcessPagination.activeSort === 'progress'"
                                        [className]="inProcessPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 5%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" *ngFor="let invoice of invoices" [routerLink]="invoice._id">
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #companyColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCompanyName(invoice) | uppercase"
                                    [matTooltipDisabled]="!checkOverflow(companyColumn)"
                                    class="companyName">{{getCompanyName(invoice) | uppercase}}</span>
                            </td>
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #codesColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCodes(invoice)"
                                    [matTooltipDisabled]="!checkOverflow(codesColumn)">{{getCodes(invoice)}}</span>
                            </td>
                            <td class="currency">{{invoice.currency | currencySymbol}} {{invoice.netAmount |
                                numberSpacing}}</td>
                            <td class="paymentDate">{{invoice.paymentDate | formatDate}}</td>
                            <td>
                                <div style="display: flex;align-items: center;">
                                    <mat-progress-bar class="audio-progress-bar" mode="determinate"
                                        [value]="invoice.clientChecks?.progress">
                                    </mat-progress-bar>
                                    <img style="z-index: 10; margin-left: -3px;" width="32px" height="32px"
                                        matTooltipClass="fs-tooltip" matTooltip="Desembolso"
                                        src='../../../../../assets/icons/attach_money.svg'>
                                </div>
                                <span class="in-progress-bar-text small-screen" matTooltipClass="fs-tooltip"
                                    [matTooltip]="invoice.clientChecks?.isFinanceReady ? 'Financiando tu operación' : 'En proceso de validación'">
                                    {{invoice.clientChecks?.isFinanceReady ? 'Financiando...' : 'Validando...'}}</span>
                                <span class="in-progress-bar-text big-screen">
                                    {{invoice.clientChecks?.isFinanceReady ? 'Financiando tu operación' : 'En proceso de
                                    validación'}}</span>
                            </td>
                            <td style="text-align: right;">
                                <div *ngIf="invoice?.clientChecks?.totalDenied > 0" class="red-badge">
                                    <span style="position: absolute; margin-left: -42px; margin-top: 12px;"
                                        [matBadge]="invoice?.clientChecks?.totalDenied > 9 ? '9+' : invoice?.clientChecks?.totalDenied"
                                        matBadgeOverlap="false"></span>
                                </div>
                                <lib-icon icon="arrow_right" size="m" color="dark-gray">
                                </lib-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="operation-cards">
                    <app-client-invoice-card *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                        [invoice]="invoice" type="in process">
                    </app-client-invoice-card>
                </div>


                <div class="paginator" *ngIf="invoices?.length > 0">
                    <app-new-paginator [totalCount]="inProcessPagination.total" [pageSize]="inProcessPagination.limit"
                        [currentPage]="inProcessPagination.currentPage"
                        (pagination)="paginationHandlerClient($event, 0)">
                    </app-new-paginator>
                </div>

                <div class="zero-state" *ngIf="invoices?.length == 0">
                    <img width="40px" height="40px" src='../../../../../assets/icons/dollar-circle.png'>
                    <span class="zero-state-title" style="margin-top: 8px;">Aquí verás tus operaciones en
                        proceso.</span>
                    <span class="zero-state-text" style="margin-top: 8px;">Haz clic en “Subir facturas” para crear una
                        operación.</span>
                </div>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Por cobrar
                    <div *ngIf="totalForCollectionCount()" class="blue-badge">
                        <span style="margin-left: 9px; display: inherit;" [matBadge]="totalForCollectionCount()"
                            matBadgeOverlap="false"></span>
                    </div>
                </ng-template>

                <table class="operation-table" *ngIf="invoices?.length > 0">
                    <thead>
                        <tr>
                            <th style="width: 22%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 1}, 'companyName')">Empresa
                                    <img style="margin-left: 2px;"
                                        *ngIf="forCollectionPagination.activeSort === 'companyName'"
                                        [className]="forCollectionPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 18%;">Facturas</th>
                            <th style="width: 16%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 1}, 'netAmount')">Monto neto
                                    <img style="margin-left: 2px;"
                                        *ngIf="forCollectionPagination.activeSort === 'netAmount'"
                                        [className]="forCollectionPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 17%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 1}, 'paymentDate')">Fecha de
                                    pago
                                    <img style="margin-left: 2px;"
                                        *ngIf="forCollectionPagination.activeSort === 'paymentDate'"
                                        [className]="forCollectionPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 22%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 1}, 'days progress')">Progreso
                                    <img style="margin-left: 2px;"
                                        *ngIf="forCollectionPagination.activeSort === 'days progress'"
                                        [className]="forCollectionPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 5%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                            (click)="updateNotification(invoice)">
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #companyColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCompanyName(invoice) | uppercase"
                                    [matTooltipDisabled]="!checkOverflow(companyColumn)"
                                    class="companyName">{{getCompanyName(invoice) | uppercase}}</span>
                            </td>
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #codesColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCodes(invoice)"
                                    [matTooltipDisabled]="!checkOverflow(codesColumn)">{{getCodes(invoice)}}</span>
                            </td>
                            <td class="currency">{{invoice.currency | currencySymbol}}
                                {{invoice.netAmount |
                                numberSpacing}}</td>
                            <td class="paymentDate">{{invoice.paymentDate | formatDate}}</td>
                            <td>
                                <div style="display: flex;align-items: center;">
                                    <img matTooltipClass="fs-tooltip" matTooltip="Desembolso"
                                        style="position: absolute;margin-top:-4px ; margin-left: -5px; z-index: 10;"
                                        src="../../../../../../assets/icons/ellipse_blue.svg" width="12px"
                                        height="12px">
                                    <mat-progress-bar class="audio-progress-bar" mode="determinate"
                                        [value]="invoice?.toBeCollectedIn === 'En mora' ? 100 : datesPercentage(invoice?.advancePaymentDate, invoice?.paymentDate)">
                                    </mat-progress-bar>
                                    <img style="z-index: 10; margin-left: -3px;" width="32px" height="32px"
                                        matTooltipClass="fs-tooltip" matTooltip="Pago"
                                        [src]="(invoice?.toBeCollectedIn === 'En mora' || invoice?.toBeCollectedIn === 0) ? '../../../../../assets/icons/attach_credit_blue.svg' : '../../../../../assets/icons/attach_credit.svg'">
                                </div>
                                <span style="font-size: 12px; display: block; margin-top: -7px;"
                                    [ngStyle]="{'color': getRemainingDays(invoice?.toBeCollectedIn, invoice?.moraDays).color}">
                                    {{getRemainingDays(invoice?.toBeCollectedIn, invoice?.moraDays).text}}</span>
                            </td>
                            <td style="text-align: right;">
                                <img *ngIf="invoice.hasNotification"
                                    style="position: absolute; margin-left: -33px; margin-top: 6px;"
                                    src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
                                <lib-icon icon="arrow_right" size="m" color="dark-gray">
                                </lib-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="operation-cards">
                    <app-client-invoice-card *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                        [invoice]="invoice" type="collection">
                    </app-client-invoice-card>
                </div>

                <div class="paginator" *ngIf="invoices?.length > 0">
                    <app-new-paginator [totalCount]="forCollectionPagination.total"
                        [currentPage]="forCollectionPagination.currentPage" [pageSize]="forCollectionPagination.limit"
                        (pagination)="paginationHandlerClient($event, 1)">
                    </app-new-paginator>
                </div>

                <div class="zero-state" *ngIf="invoices?.length == 0">
                    <img width="40px" height="40px" src='../../../../../assets/icons/card-tick.png'>
                    <span class="zero-state-title" style="margin-top: 8px;">No tienes operaciones por cobrar.</span>
                    <span class="zero-state-text" style="margin-top: 8px;">Aquí verás tus operaciones financiadas y en
                        espera de cobro.</span>
                </div>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Cobrada
                    <div *ngIf="totalCollectedCount()" class="blue-badge">
                        <span style="margin-left: 9px; display: inherit;" [matBadge]="totalCollectedCount()"
                            matBadgeOverlap="false"></span>
                    </div>
                </ng-template>

                <table class="operation-table" *ngIf="invoices?.length > 0">
                    <thead>
                        <tr>
                            <th style="width: 21%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 2}, 'companyName')">Empresa
                                    <img style="margin-left: 2px;"
                                        *ngIf="collectedPagination.activeSort === 'companyName'"
                                        [className]="collectedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 14%;">Facturas</th>
                            <th style="width: 16%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 2}, 'netAmount')">Monto neto
                                    <img style="margin-left: 2px;"
                                        *ngIf="collectedPagination.activeSort === 'netAmount'"
                                        [className]="collectedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 17%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 2}, 'paymentDate')">Fecha de
                                    pago
                                    <img style="margin-left: 2px;"
                                        *ngIf="collectedPagination.activeSort === 'paymentDate'"
                                        [className]="collectedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 27%;">
                                <span class="pointer sorting"
                                    (click)="filterTabs({index: 2}, 'actualPaymentDate')">Fecha de pago real (mora)
                                    <img style="margin-left: 2px;"
                                        *ngIf="collectedPagination.activeSort === 'actualPaymentDate'"
                                        [className]="collectedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 5%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                            (click)="updateNotification(invoice)">
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #companyColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCompanyName(invoice) | uppercase"
                                    [matTooltipDisabled]="!checkOverflow(companyColumn)"
                                    class="companyName">{{getCompanyName(invoice) | uppercase}}</span>
                            </td>
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #codesColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCodes(invoice)"
                                    [matTooltipDisabled]="!checkOverflow(codesColumn)">{{getCodes(invoice)}}</span>
                            </td>
                            <td class="currency">{{invoice.currency | currencySymbol}} {{invoice.netAmount |
                                numberSpacing}}</td>
                            <td>{{invoice.paymentDate | formatDate}}</td>
                            <td class="paymentDate">
                                {{ (service.calculateCollectedDays(invoice.paymentDate, invoice.actualPaymentDate) > 0)
                                ?
                                (invoice.actualPaymentDate | formatDate) + ' (' +
                                service.calculateCollectedDays(invoice.paymentDate, invoice.actualPaymentDate)
                                + ((service.calculateCollectedDays(invoice.paymentDate, invoice.actualPaymentDate) ===
                                1)
                                ? ' día)' : ' días)')
                                :
                                (invoice.actualPaymentDate | formatDate)}}
                            </td>
                            <td style="display: flex;justify-content: end; align-items: center;">
                                <span matTooltipClass="fs-tooltip"
                                    matTooltip="Descargar facturas emitidas por el servicios de Financiamiento">
                                    <img *ngIf="invoice.invoicings?.length" (click)="downloadInvoicing($event, invoice)"
                                        [ngStyle]="{'margin-right': invoice.hasNotification ? '0px' : '22px'}"
                                        src="../../../../../../assets/icons/download-file.png" width="20px"
                                        height="20px">
                                </span>
                                <img *ngIf="invoice.hasNotification" style="margin-left: 10px;"
                                    src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
                                <lib-icon icon="arrow_right" size="m" color="dark-gray">
                                </lib-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="operation-cards">
                    <app-client-invoice-card *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                        [invoice]="invoice" type="finalized">
                    </app-client-invoice-card>
                </div>

                <div class="paginator" *ngIf="invoices?.length > 0">
                    <app-new-paginator [totalCount]="collectedPagination.total" [pageSize]="collectedPagination.limit"
                        [currentPage]="collectedPagination.currentPage"
                        (pagination)="paginationHandlerClient($event, 2)">
                    </app-new-paginator>
                </div>

                <div class="zero-state" *ngIf="invoices?.length == 0">
                    <img width="40px" height="40px" src='../../../../../assets/icons/receipt-text.png'>
                    <span class="zero-state-title" style="margin-top: 8px;">No tienes operaciones cobradas.</span>
                    <span class="zero-state-text" style="margin-top: 8px;">Aquí verás tus operaciones ya pagadas y
                        finalizadas.</span>
                </div>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Desaprobada
                    <div *ngIf="totalDisapprovedCount()" class="red-badge">
                        <span style="margin-left: 9px; display: inherit;" [matBadge]="totalDisapprovedCount()"
                            matBadgeOverlap="false"></span>
                    </div>
                </ng-template>

                <table class="operation-table" *ngIf="invoices?.length > 0">
                    <thead>
                        <tr>
                            <th style="width: 22%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 3}, 'companyName')">Empresa
                                    <img style="margin-left: 2px;"
                                        *ngIf="disapprovedPagination.activeSort === 'companyName'"
                                        [className]="disapprovedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 35%;">Facturas</th>
                            <th style="width: 19%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 3}, 'netAmount')">Monto neto
                                    <img style="margin-left: 2px;"
                                        *ngIf="disapprovedPagination.activeSort === 'netAmount'"
                                        [className]="disapprovedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 19%;">
                                <span class="pointer sorting" (click)="filterTabs({index: 3}, 'paymentDate')">Fecha de
                                    pago
                                    <img style="margin-left: 2px;"
                                        *ngIf="disapprovedPagination.activeSort === 'paymentDate'"
                                        [className]="disapprovedPagination.isSortAsc ? 'arrow-downward' : 'arrow-upward'">
                                </span>
                            </th>
                            <th style="width: 5%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                            (click)="updateNotification(invoice)">
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #companyColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCompanyName(invoice) | uppercase"
                                    [matTooltipDisabled]="!checkOverflow(companyColumn)"
                                    class="companyName">{{getCompanyName(invoice) | uppercase}}</span>
                            </td>
                            <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
                                #codesColumn>
                                <span matTooltipClass="fs-tooltip" [matTooltip]="getCodes(invoice)"
                                    [matTooltipDisabled]="!checkOverflow(codesColumn)">{{getCodes(invoice)}}</span>
                            </td>
                            <td class="currency">{{invoice.currency | currencySymbol}} {{invoice.netAmount |
                                numberSpacing}}</td>
                            <td>{{invoice.paymentDate | formatDate}}</td>
                            <td style="display: flex;justify-content: end; align-items: center;">
                                <img *ngIf="invoice.hasNotification"
                                    style="margin-left: 10px;filter: invert(16%) sepia(92%) saturate(7426%) hue-rotate(6deg) brightness(98%) contrast(117%);"
                                    src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
                                <lib-icon icon="arrow_right" size="m" color="dark-gray">
                                </lib-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="operation-cards">
                    <app-client-invoice-card *ngFor="let invoice of invoices" [routerLink]="invoice._id"
                        [invoice]="invoice" type="disapproved">
                    </app-client-invoice-card>
                </div>

                <div class="paginator" *ngIf="invoices?.length > 0">
                    <app-new-paginator [totalCount]="disapprovedPagination.total"
                        [currentPage]="disapprovedPagination.currentPage" [pageSize]="disapprovedPagination.limit"
                        (pagination)="paginationHandlerClient($event, 3)">
                    </app-new-paginator>
                </div>

                <div class="zero-state" *ngIf="invoices?.length == 0">
                    <img width="40px" height="40px" src='../../../../../assets/icons/archive.png'>
                    <span class="zero-state-title" style="margin-top: 8px;">No tienes operaciones desaprobadas.</span>
                    <span class="zero-state-text" style="margin-top: 8px;">Aquí verás tus operaciones que no se llegaron
                        a financiar.</span>
                </div>
            </mat-tab>
        </mat-tab-group>



    </ng-container>

    <ng-container *allowedRoles="['admin','super admin', 'client happiness']">
        <form [formGroup]="filtersForm" (ngSubmit)="applyFilter()">
            <div class="filter-row">
                <mat-form-field appearance="fill">
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="statuses" multiple>
                        <mat-option *ngFor="let status of statusesList" [value]="status">
                            {{ status | toSpanish : 'FEMALE' | capitalizeFirstLetters }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="toggle toggle-desk">
                    <app-slide-toggle [checked]="isConfirming" [firstLabel]="'Factoring'" [secondLabel]="'Confirming'"
                        (selectionChanged)="setInvoiceType($event)">
                    </app-slide-toggle>
                </div>
            </div>
            <div class="filter-row">
                <app-autocomplete label="Empresa pagadora" searchFor="debtor" [selectedOption]="selectedDebtor"
                    formControlName="debtor"></app-autocomplete>
                <app-autocomplete label="Empresa giradora" searchFor="{{searchinType}}"
                    [selectedOption]="selectedIssuer" formControlName="issuer"></app-autocomplete>
            </div>

            <div class="filter-row">
                <mat-form-field appearance="fill">
                    <mat-label>Código de factura</mat-label>
                    <input matInput formControlName="invoiceCode">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Rango de cobro</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Desde" formControlName="createdFrom">
                        <input matEndDate placeholder="Hasta" formControlName="createdTo">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <div class="filter-row">
                <fs-ui-button (onClick)="resetFiltersToDefaults()" [label]="'Predeterminados'" [type]="'secondary'">
                </fs-ui-button>
                <fs-ui-button (onClick)="applyFilter()" [label]="'Aplicar'"></fs-ui-button>
                <fs-ui-button (onClick)="resetFilters()" [label]="'Borrar'" [type]="'tertiary'"></fs-ui-button>
            </div>
        </form>

        <!-- <ng-container *ngIf="invoices$ | async as clientInvoices">
            <ng-container *ngIf="clientInvoices?.length > 0">
                <app-page-title *allowedRoles="['client']" title="Facturas"
                    description="Haz clic en la factura para ver los detalles.">
                </app-page-title>
            </ng-container>
            <ng-container *ngIf="clientInvoices?.length < 1">
                <app-page-title *allowedRoles="['client']" title="Facturas"
                    description="Aún no has subido ninguna factura. Haz clic en el botón para empezar.">
                </app-page-title>
            </ng-container>
        </ng-container> -->

        <ng-container *ngIf="invoices; else no_items_found">
            <ng-container *ngFor="let invoice of invoices">
                <lib-invoice-card [entity_data]="invoice" [routerLink]="invoice._id"
                    [cavaliStatusFlag]="invoice.cavaliStatusFlag" [status]="invoice.status"
                    [viewerRole]="(role === 'admin' || role === 'super admin') ? 'admin' : 'ch'"
                    [operationType]="invoice.isConfirming" [pendingEmailCount]="invoice.pendingEmailCount"
                    (onFilterByDebtor)="filterByDebtor($event)" (onFilterByClient)="filterByClient($event)">
                </lib-invoice-card>
            </ng-container>

            <app-paginator [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
                (pagination)="paginationHandler($event)">
            </app-paginator>

        </ng-container>
    </ng-container>
</div>


<!--Empty ooportunities template-->
<ng-container *allowedRoles="['investor']">
    <div class="empty-opportunities container" *ngIf="(invoices$ | async)?.length === 0">
        <img class="" src='../../../../../assets/icons/empty-opportunities.svg'>
        <p class="disclaimer">¡Volaron las oportunidades! Estamos trabajando para conseguir nuevas :). Vuelve más tarde
            o revisa nuestro canal de <a href="https://t.me/Finsmart" target="_blank">Telegram.</a></p>
    </div>
</ng-container>
<ng-template #no_items_found>
    <div class="no-items-found">
        No se encontraron artículos con estos criterios
    </div>
</ng-template>