import { MixpanelService } from './../../../shared/services/mixpanel.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, act } from '@ngrx/effects';
import { AuthActions } from './auth.action-types';
import { AuthService } from '../services/auth.service';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  tap,
  map,
  catchError,
  mergeMap,
  switchMap,
  shareReplay,
  concatMap,
} from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedActions } from 'src/app/shared/state/shared.action-types';
import { BankAccountsActions } from '../../bank-accounts/state/bank-accounts.action-types';
import { Toaster } from 'ngx-toast-notifications';
import { showToast, hideToast } from 'src/app/shared/util/toaster.util';
import { NgxSpinnerService } from 'ngx-spinner';
import { calculateTokenValidityDuration } from 'src/app/shared/util/jwt.util';
import { FinancialTransactionActions } from '../../financial-transactions/state/financial-transactions.action-types';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { UsersActions } from '../../users/state/users.action-types';
import { InvoicesActions } from '../../invoices/state/invoices.action-types';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { accessToken } from './auth.selectors';
import { CapitalizeFirstLettersPipe } from 'src/app/shared/pipes/capitalize-first-letters.pipe';

@Injectable()
export class AuthEffects {
  private jwtHelper: JwtHelperService;

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogIn),
        tap(() => {
          this.spinner.show();
        }),
        mergeMap((action) =>
          this.authService.createAuth(action.loginInfo).pipe(
            switchMap((authResult) => {
              const decodedToken = this.jwtHelper.decodeToken(
                authResult.accessToken
              );

              this.authService
                .setTimeoutForAccessToken(authResult.accessToken)
                .then();

              let profile;

              if (
                !decodedToken.hasOwnProperty('isConfirming') &&
                decodedToken.role === Roles.CLIENT
              ) {
                decodedToken.isConfirming = false;
              }

              if (!decodedToken.hasOwnProperty('legalCounter')) {
                decodedToken.legalCounter = 0;
              }

              if (
                !decodedToken.hasOwnProperty('legalRep') &&
                !decodedToken.hasOwnProperty('profession') &&
                !decodedToken.hasOwnProperty('legalComment') &&
                decodedToken.role === Roles.CLIENT
              ) {
                decodedToken.legalRep = null;
                decodedToken.legalComment = null;
                decodedToken.profession = null;
              }

              if (decodedToken.hasOwnProperty('conversions')) {
                if (
                  decodedToken.conversions.hasOwnProperty('conversionCount')
                ) {
                  decodedToken.conversionCount =
                    decodedToken.conversions.conversionCount;
                }

                if (
                  decodedToken.conversions.hasOwnProperty('conversionAmount')
                ) {
                  decodedToken.conversionAmount =
                    decodedToken.conversions.conversionAmount;
                }

                if (
                  decodedToken.conversions.hasOwnProperty('conversionLastTime')
                ) {
                  decodedToken.conversionLastTime =
                    decodedToken.conversions.conversionLastTime;
                }
              }

              if (decodedToken.role === Roles.CLIENT) {
                profile = {
                  _id: decodedToken._id,
                  role: decodedToken.role,
                  names: decodedToken.names,
                  familyNames: decodedToken.familyNames,
                  isBlocked: decodedToken.isBlocked,
                  status: decodedToken.status,
                  companyRuc: decodedToken.companyRuc,
                  isConfirming: decodedToken.isConfirming,
                  legalRep: decodedToken.legalRep,
                  profession: decodedToken.profession,
                  legalCounter: decodedToken.legalCounter,
                  legalComment: decodedToken.legalComment,
                  channel: decodedToken.channel,
                  companyName: decodedToken.companyName,
                  isNewsletter: decodedToken.isNewsletter,
                  conversionCount: decodedToken.conversionCount || 0,
                  conversionAmount: decodedToken.conversionAmount || 0,
                  conversionLastTime: decodedToken.conversionLastTime || null,
                  accountManager: decodedToken.accountManager || '-',
                  feedbackAnimationClosed:
                    decodedToken.feedbackAnimationClosed || false,
                  isContractUpdateSeen: decodedToken.isContractUpdateSeen,
                };
                if (decodedToken.whatsappStatus)
                  profile.whatsappStatus = decodedToken.whatsappStatus;
                if (decodedToken.whatsappStatus)
                  profile.whatsappActivationResentAt =
                    decodedToken.whatsappActivationResentAt;
                if (decodedToken.whatsappActivatedOnce)
                  profile.whatsappActivatedOnce =
                    decodedToken.whatsappActivatedOnce;
              } else {
                profile = {
                  _id: decodedToken._id,
                  role: decodedToken.role,
                  names: decodedToken.names,
                  familyNames: decodedToken.familyNames,
                  isBlocked: decodedToken.isBlocked,
                  status: decodedToken.status,
                  balance: Number(decodedToken.balance),
                  channel: decodedToken.channel,
                  isNewsletter: decodedToken.isNewsletter,
                  conversionCount: decodedToken.conversionCount || 0,
                  conversionAmount: decodedToken.conversionAmount || 0,
                  hasDeposit: decodedToken.hasDeposit || false,
                  hasInvestment: decodedToken.hasInvestment || false,
                  hasInvestmentDelayed:
                    decodedToken.hasInvestmentDelayed || false,
                  depositsAmount: decodedToken.depositsAmount || 0,
                  balancePEN: decodedToken.balancePEN || 0,
                  balanceUSD: decodedToken.balanceUSD || 0,
                  phoneNumber: decodedToken.phoneNumber || '',
                  segment: decodedToken.segment || '',
                  accountManager: decodedToken.accountManager || '-',
                  taxResidency: decodedToken.taxResidency || '',
                  idType: decodedToken.idType || '',
                  feedbackAnimationClosed:
                    decodedToken.feedbackAnimationClosed || false,
                };
              }

              return [
                AuthActions.LogInSuccess({
                  accessToken: authResult.accessToken,
                  role: decodedToken.role,
                }),
                SharedActions.HideSideMenu(),
                UsersActions.SetProfile(profile),
              ];
            }),
            catchError((error) => of(AuthActions.LoginFailed({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  restoreSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.RestoreSession),
        switchMap((action) => {
          if (this.jwtHelper.isTokenExpired(action.token)) {
            return [AuthActions.LogOut()];
          } else {
            const decodedToken = this.jwtHelper.decodeToken(action.token);

            this.authService.setTimeoutForAccessToken(action.token).then();

            let profile;
            if (decodedToken.role === Roles.CLIENT) {
              if (!decodedToken.hasOwnProperty('isConfirming')) {
                decodedToken.isConfirming = false;
              }

              if (!decodedToken.hasOwnProperty('legalCounter')) {
                decodedToken.legalCounter = 0;
              }

              if (
                !decodedToken.hasOwnProperty('legalRep') &&
                !decodedToken.hasOwnProperty('profession') &&
                !decodedToken.hasOwnProperty('legalComment') &&
                decodedToken.role === Roles.CLIENT
              ) {
                decodedToken.legalRep = null;
                decodedToken.legalComment = null;
                decodedToken.profession = null;
              }

              if (decodedToken.hasOwnProperty('conversions')) {
                if (
                  decodedToken.conversions.hasOwnProperty('conversionCount')
                ) {
                  decodedToken.conversionCount =
                    decodedToken.conversions.conversionCount;
                }

                if (
                  decodedToken.conversions.hasOwnProperty('conversionAmount')
                ) {
                  decodedToken.conversionAmount =
                    decodedToken.conversions.conversionAmount;
                }

                if (
                  decodedToken.conversions.hasOwnProperty('conversionLastTime')
                ) {
                  decodedToken.conversionLastTime =
                    decodedToken.conversions.conversionLastTime;
                }
              }

              profile = {
                _id: decodedToken._id,
                role: decodedToken.role,
                names: decodedToken.names,
                isBlocked: decodedToken.isBlocked,
                status: decodedToken.status,
                companyRuc: decodedToken.companyRuc,
                isConfirming: decodedToken.isConfirming,
                familyNames: decodedToken.familyNames,
                legalRep: decodedToken.legalRep,
                profession: decodedToken.profession,
                legalCounter: decodedToken.legalCounter,
                legalComment: decodedToken.legalComment,
                channel: decodedToken.channel,
                companyName: decodedToken.companyName,
                isNewsletter: decodedToken.isNewsletter,
                conversionCount: decodedToken.conversionCount || 0,
                conversionAmount: decodedToken.conversionAmount || 0,
                conversionLastTime: decodedToken.conversionLastTime || null,
                feedbackAnimationClosed:
                  decodedToken.feedbackAnimationClosed || false,
                isContractUpdateSeen: decodedToken.isContractUpdateSeen,
              };
              if (decodedToken.whatsappStatus)
                profile.whatsappStatus = decodedToken.whatsappStatus;
              if (decodedToken.whatsappStatus)
                profile.whatsappActivationResentAt =
                  decodedToken.whatsappActivationResentAt;
              if (decodedToken.whatsappActivatedOnce)
                profile.whatsappActivatedOnce =
                  decodedToken.whatsappActivatedOnce;
            } else {
              profile = {
                _id: decodedToken._id,
                role: decodedToken.role,
                names: decodedToken.names,
                isBlocked: decodedToken.isBlocked,
                status: decodedToken.status,
                familyNames: decodedToken.familyNames,
                balance: Number(decodedToken.balance),
                channel: decodedToken.channel,
                isNewsletter: decodedToken.isNewsletter,
                conversionCount: decodedToken.conversionCount || 0,
                conversionAmount: decodedToken.conversionAmount || 0,
                conversionLastTime: decodedToken.conversionLastTime || null,
                hasDeposit: decodedToken.hasDeposit || false,
                hasInvestment: decodedToken.hasInvestment || false,
                hasInvestmentDelayed:
                  decodedToken.hasInvestmentDelayed || false,
                depositsAmount: decodedToken.depositsAmount || 0,
                balancePEN: decodedToken.balancePEN || 0,
                balanceUSD: decodedToken.balanceUSD || 0,
                phoneNumber: decodedToken.phoneNumber || '',
                taxResidency: decodedToken.taxResidency || '',
                idType: decodedToken.idType || '',
                feedbackAnimationClosed:
                  decodedToken.feedbackAnimationClosed || false,
              };
            }
            return [
              AuthActions.SessionReinistated({ accessToken: action.token }),
              UsersActions.SetProfile(profile),
            ];
          }
        })
      ),
    { dispatch: true }
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogInSuccess),
        tap((action) => {
          const redirectUrl = sessionStorage.getItem('redirectUrl');

          if (redirectUrl && redirectUrl != '/auth') {
            this.router.navigateByUrl(redirectUrl);
          } else {
            this.router.navigateByUrl('/');
          }
        }),
        switchMap((action) => {
          return [AuthActions.SaveSession({ token: action.accessToken })];
        }),
        tap(() => {
          hideToast();
          //this.spinner.hide();
        })
      ),
    { dispatch: true }
  );

  saveSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.SaveSession),
        tap((action) => {
          localStorage.setItem('access_token', action.token);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LogOut),
        tap(() => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('users_filter');
          localStorage.removeItem('invoices_filter');
          localStorage.removeItem('transactions_filter');
          localStorage.removeItem('risk-debtors-pagination');
          localStorage.removeItem('toggle_currency_investment');
          localStorage.removeItem('toggle_currency');
          localStorage.removeItem('monitoring_filters');
          localStorage.removeItem('pagination_pending_evaluation');
          localStorage.removeItem('tab_opportunities');
          localStorage.removeItem('actual_investments_pagination');
          localStorage.removeItem('transactions_pagination');
          localStorage.removeItem('toggle_transaction_currency');
          localStorage.removeItem('tab_transactions');
          localStorage.removeItem('client_invoices_pagination');
          localStorage.removeItem('operations_filters');
          localStorage.removeItem('tab_operation_detail');
          localStorage.removeItem('previousURL');
          localStorage.removeItem('previousURLID');
          localStorage.removeItem('client_invoices_table');
          localStorage.removeItem('productTourStart');
          localStorage.clear();

          this.router.navigateByUrl('/auth');
          this.socketService.disconnect();
          this.gtmService.resetIdentity();
          this.authService.clearSessionTimer();
          this.mixpanelService.newEvent('Logout');
          this.mixpanelService.reset();
          //window.location.href = "/auth"; //safe net for statee reset + closing any open modals/overlays
        }),
        switchMap(() => [
          SharedActions.ResetShared(),
          FinancialTransactionActions.Reset(),
          BankAccountsActions.ResetBankAccounts(),
          UsersActions.Reset(),
          InvoicesActions.Reset(),
        ])
      ),
    { dispatch: true }
  );

  manualLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.ManualLogOut),
        tap(() => {
          sessionStorage.removeItem('redirectUrl');
        }),
        switchMap((action) => {
          return [AuthActions.LogOut()];
        })
      ),
    { dispatch: true }
  );

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginFailed),
        tap((error) => {
          if (error.error.error.startsWith('VALIDATION.ERROR.PASSWORD')) {
            showToast(this.toaster, 'Sus credenciales son inválidas', false);
          } else {
            switch (error.error.error) {
              case 'AUTH.INVALID_CREDENTIALS':
              case 'AUTH.USER_DOESNT_EXIST':
                showToast(
                  this.toaster,
                  'Sus credenciales son inválidas.',
                  false
                );
                break;
              case 'AUTH.USER_BLOCKED':
                showToast(
                  this.toaster,
                  'Su cuenta ha sido bloqueada, contáctenos si cree que es un error.',
                  false
                );
                break;
              case 'AUTH.USER_IS_IN_DISAPPROVED_STATE':
              case 'AUTH.PENDING_ADMIN_APPROVAL':
                showToast(
                  this.toaster,
                  'Su cuenta ha sido creada pero está pendiente de la aprobación del administrador.',
                  false
                );
                break;
              case 'AUTH.PENDING_EMAIL_VERIFICATION':
                showToast(
                  this.toaster,
                  'Revise su correo electrónico para habilitar su cuenta.',
                  false
                );
                break;
              default:
                showToast(this.toaster, 'Algo salió mal.', false);
                break;
            }
          }

          this.spinner.hide();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private gtmService: GTMService,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private socketService: SocketService,
    private mixpanelService: MixpanelService,
    private capitalizeFirstLettersPipe: CapitalizeFirstLettersPipe
  ) {
    this.jwtHelper = new JwtHelperService();
  }
}
