<div class="modal-container">
  <p class="title u-mb-3">Descargar hoja de resumen</p>
  <form [formGroup]="form">
    <p class="body">
        Ten en cuenta que la hoja de resumen se modificará si se cambia el monto neto o la fecha de pago.
    </p>

    <div class="checkbox-container">
      <app-checkbox formControlName="notShowAgain" color="primary">
      </app-checkbox>
      <p class="caption">No volver a mostrar</p>
    </div>
  </form>

  <div class="d-flex button-container">
    <div (click)="cancel()" class="button-delete accept-button">Cerrar</div>
    <div (click)="confirm()" class="button-delete accept-button">Descargar</div>
  </div>
</div>
