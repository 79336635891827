import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { BehaviorSubject } from "rxjs";
import { EvaluationInformationPopupComponent } from "../evaluation-information-popup/evaluation-information-popup.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-template-user-detail",
  templateUrl: "./template-user-detail.component.html",
  styleUrls: ["./template-user-detail.component.scss"],
})
export class TemplateUserDetailComponent implements OnInit {
  @Input() evaluation;
  @Input() role;
  cciCopied = new BehaviorSubject<any>(false);
  fullName;
  @Output() documentDialog = new EventEmitter();
  constructor(private clipboard: Clipboard, public dialog: MatDialog) {}

  ngOnInit(): void {
    // this.getFullName()
  }

  getFullName() {
    // let name = this.evaluation.accountManager.names.split(' ')
    // let familiyName = this.evaluation.accountManager.familyNames.split(' ')
    // name = name.length === 1 ? name : name[0];
    // familiyName = familiyName.length === 1 ? familiyName : familiyName[0];
    // this.fullName = name + ' ' + familiyName
    // console.log(this.fullName)
  }

  copyCCI() {
    this.copyToClipBoard(this.evaluation.debtor.companyRuc);
    this.cciCopied.next(true);
    setTimeout(() => {
      this.cciCopied.next(false);
    }, 1000);
  }

  copyToClipBoard(value) {
    this.clipboard.copy(value);
  }

  openDocumentDialog(evaluation) {
    this.documentDialog.emit(evaluation);
  }

  openInformationModal() {
    if (this.modalState() === "disabled") return;

    const dialogRef = this.dialog.open(EvaluationInformationPopupComponent, {
      width: "500px",
      height: "auto",
      autoFocus: false,
      data: this.evaluation,
    });
  }

  modalState() {
    if (
      (this.evaluation?.debtorLineProposalV4 &&
        this.evaluation?.debtorLineProposalV4 !== "") ||
      (this.evaluation?.website && this.evaluation?.website !== "") ||
      (this.evaluation?.socialMedia && this.evaluation?.socialMedia !== "") ||
      (this.evaluation?.companyDetail &&
        this.evaluation?.companyDetail !== "") ||
      (this.evaluation?.debtorActivity &&
        this.evaluation?.debtorActivity !== "") ||
      (this.evaluation?.debtorSector && this.evaluation?.debtorSector !== "") ||
      this.evaluation?.documents?.length > 0
    ) {
      return "enabled";
    }
    return "disabled";
  }

  stringToList(value: string) {
    const items = value
      .trim()
      .split(",")
      .map((item) => item.trim());

    if (items.length > 2) {
      return [items[0], items[1], "..."];
    }

    return items;
  }
}
